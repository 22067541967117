<template>
  <div>
    <el-row>
      <el-button
        icon="el-icon-circle-plus-outline"
        type="primary"
        v-throttle
        @click="handleAddMaterialTagBtnClick"
        >添加物品标签</el-button
      >
      <el-button icon="el-icon-upload2" v-throttle>导入</el-button>
    </el-row>
    <div class="table-container">
      <div class="left-table">
        <el-form inline :model="materialTagCondition">
          <!-- 标签编号 -->
          <el-form-item class="formItemBoxStyle" label="标签编号">
            <el-input
              v-model.trim="materialTagCondition.itemTagCode"
              placeholder="请输入"
              style="width: 170px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              @blur="fetchTagListData"
            ></el-input>
          </el-form-item>
          <!-- 标签名称 -->
          <el-form-item class="formItemBoxStyle" label="标签名称">
            <el-input
              v-model.trim="materialTagCondition.itemTagName"
              placeholder="请输入"
              style="width: 170px;margin-right: 50px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              @blur="fetchTagListData"
            ></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button v-throttle @click="handleResetMaterialTagBtn"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              v-throttle
              @click="handleQueryMaterialTagBtn"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          :data="materialTagTableDataList"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center', cursor: 'pointer' }"
          @row-click="handleTableRowClick"
          highlight-current-row
          class="materialTagTableContainer"
        >
          <el-table-column
            label="标签编号"
            prop="itemTagCode"
          ></el-table-column>
          <el-table-column
            label="标签名称"
            prop="itemTagName"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scoped">
              <span
                style="
                  padding-right: 5px;
                  border-right: 1px solid #ccc;
                  color: red;
                  cursor: pointer;
                "
                @click="handleDeleteTagBtnClick(scoped.row.itemTagId)"
                >删除</span
              >
              <span
                @click="handleEditTagBtnClick(scoped.row)"
                style="margin-left: 5px; color: #00f; cursor: pointer"
                >编辑</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="right-table">
        <div class="title">关联物品</div>
        <div style="text-align: right">
          <el-input
            style="width: 200px"
            placeholder="请输入编号"
            v-model="materialInput"
            @blur="handleSearchInputBlur"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i
          ></el-input>
        </div>
        <el-row style="margin-top: 20px">
          <el-table
            border
            :data="materialTableDataList"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              label="序号"
              type="index"
              width="80"
            ></el-table-column>
            <el-table-column
              label="国标编号"
              prop="internationCode"
            ></el-table-column>
            <el-table-column label="名称" prop="materialName"></el-table-column>
            <el-table-column
              label="规格型号功能"
              prop="materialSpecifications"
            ></el-table-column>
          </el-table>
        </el-row>
      </div>
    </div>
    <!-- 分页组件 -->
    <el-row style="margin-top: 30px">
      <div style="display: flex; justify-content: space-between">
        <el-pagination
          @size-change="handleSizeChangeTagTableDataMsgPage"
          @current-change="handleCurrentChangeTagTableDataMsgPage"
          :current-page="tagTableDataMsgPage.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="tagTableDataMsgPage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tagTableDataMsgPage.pageTotal"
        ></el-pagination>
        <el-pagination
          @size-change="handleSizeChangeClassifyTableDataMsgPage"
          @current-change="handleCurrentChangeClassifyTableDataMsgPage"
          :current-page="classifyTableDataMsgPage.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="classifyTableDataMsgPage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="classifyTableDataMsgPage.pageTotal"
          style="margin-right: 50px"
        ></el-pagination>
      </div>
    </el-row>
    <el-dialog
      :title="addOrEditMaterialTag === 1 ? '新建物品标签' : '编辑物品标签'"
      width="700px"
      :visible.sync="addOrEditTagDialogVisible"
      top="12%"
    >
      <div style="margin: 0 auto; width: 500px">
        <el-form
          :model="addOrEditTagForm"
          ref="addOrEditTagForm"
          :rules="rules"
        >
          <div class="form-item-container">
            <el-form-item prop="itemTagCode">
              <span class="redStar">标签编号：</span>
              <el-input
                v-model.trim="addOrEditTagForm.itemTagCode"
                placeholder="请输入"
                maxlength="12"
                style="width: 350px"
                onkeyup="this.value=this.value.replace(/\s/g,'')"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form-item-container">
            <el-form-item prop="itemTagName">
              <span class="redStar">标签名称：</span>
              <el-input
                v-model.trim="addOrEditTagForm.itemTagName"
                placeholder="请输入"
                maxlength="12"
                style="width: 350px"
                onkeyup="this.value=this.value.replace(/\s/g,'')"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item prop="internationalCode">
            <div style="display: flex">
              <div style="margin-left: 10px">备注说明：</div>
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="addOrEditTagForm.description"
                style="width: 350px"
                resize="none"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button
              @click="
                () => {
                  addOrEditTagDialogVisible = false;
                }
              "
              >取消</el-button
            >
            <el-button type="primary" @click="handleConfirmAddOrEditTagBtnClick"
              >确认</el-button
            >
          </el-form-item>
          <el-form-item> </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterialTagInfoApi,
  addMaterialTagApi,
  deleteMaterialTagApi,
  getMaterialTagDetailApi,
  editMaterialTagApi,
} from "@/api/standard/tagManagement.js";
import { getClassifyMaterialListApi } from "@/api/standard/standardDeviceLib.js";
export default {
  name: "tagManagement",
  data() {
    return {
      materialTagCondition: {
        itemTagCode: "",
        itemTagName: "",
      },
      materialTagTableDataList: [],
      materialTableDataList: [],
      tempMaterialTableDataList: [],
      tagTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      addOrEditMaterialTag: 1, // 1表示新增，0表示编辑
      addOrEditTagDialogVisible: false,
      addOrEditTagForm: {
        itemTagName: "",
        itemTagCode: "",
        description: "",
      },
      rules: {
        itemTagName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
        ],
        itemTagCode: [
          { required: true, message: "请输入标签编号", trigger: "blur" },
        ],
      },
      currentTagId: "",
      classifyTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      materialInput: "",
    };
  },
  created() {
    this.fetchTagListData();
  },
  methods: {
    // 获取标签数据
    fetchTagListData() {
      getMaterialTagInfoApi(
        this.tagTableDataMsgPage.pageIndex,
        this.tagTableDataMsgPage.pageSize,
        this.materialTagCondition
      ).then((res) => {
        if (res.success) {
          this.materialTagTableDataList = res.data;
          this.tagTableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取物品标准数据
    fetchMaterialStandardData() {},
    // 新增标签按钮
    handleAddMaterialTagBtnClick() {
      this.addOrEditMaterialTag = 1;
      this.addOrEditTagForm = {
        itemTagName: "",
        itemTagCode: "",
        description: "",
      };
      this.addOrEditTagDialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.addOrEditTagForm.clearValidate()
      })
    },
    // 删除标签按钮
    handleDeleteTagBtnClick(itemTagId) {
      this.$confirm("确定删除该物品标签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMaterialTagApi(itemTagId).then((res) => {
            if (res.success) {
              this.$message.success("删除成功!");
              this.fetchTagListData();
              return;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消删除`,
          });
        });
    },
    // 编辑标签按钮
    handleEditTagBtnClick(row) {
      this.addOrEditMaterialTag = 0;
      // 回填数据
      this.addOrEditTagForm = {
        itemTagName: row.itemTagName,
        itemTagCode: row.itemTagCode,
        description: row.description,
        itemTagId: row.itemTagId,
      };
      this.addOrEditTagDialogVisible = true;
    },
    // 新建或编辑确认按钮
    handleConfirmAddOrEditTagBtnClick() {
      this.$refs.addOrEditTagForm.validate((val) => {
        if (val) {
          if (this.addOrEditMaterialTag === 1) {
            // 进行新建操作
            addMaterialTagApi(this.addOrEditTagForm).then((res) => {
              if (res.success) {
                this.$message.success("新建标签成功");
                this.fetchTagListData();
                this.addOrEditTagDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 进行编辑操作
            editMaterialTagApi(this.addOrEditTagForm).then((res) => {
              if (res.success) {
                this.$message.success("修改标签成功");
                this.fetchTagListData();
                this.addOrEditTagDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 切换页容量
    handleSizeChangeTagTableDataMsgPage(val) {
      this.tagTableDataMsgPage.pageIndex = 1;
      this.tagTableDataMsgPage.pageSize = val;
      this.fetchTagListData();
    },
    // 切换当前页
    handleCurrentChangeTagTableDataMsgPage(val) {
      this.tagTableDataMsgPage.pageIndex = val;
      this.fetchTagListData();
    },
    // 重置表单
    handleResetMaterialTagBtn() {
      this.materialTagCondition = {
        itemTagCode: "",
        itemTagName: "",
      };
      this.tagTableDataMsgPage.pageIndex = 1;
      this.tagTableDataMsgPage.pageSize = 10;
      this.fetchTagListData();
    },
    // 查询表格
    handleQueryMaterialTagBtn() {
      this.tagTableDataMsgPage.pageIndex = 1;
      this.tagTableDataMsgPage.pageSize = 10;
      this.fetchTagListData();
    },
    // 表格行点击事件
    handleTableRowClick(row, column, event) {
      this.currentTagId = row.itemTagId;
      // 这里获取数据重置右边表格
      this.fetchClassifyMaterialList();
    },
    fetchClassifyMaterialList() {
      getClassifyMaterialListApi(
        this.classifyTableDataMsgPage.pageIndex,
        this.classifyTableDataMsgPage.pageSize,
        this.currentTagId
      ).then((res) => {
        if (res.success) {
          this.materialTableDataList = res.data;
          this.classifyTableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleSizeChangeClassifyTableDataMsgPage(val) {
      this.classifyTableDataMsgPage.pageSize = val;
      this.classifyTableDataMsgPage.pageIndex = 1;
      this.fetchClassifyMaterialList();
    },
    handleCurrentChangeClassifyTableDataMsgPage(val) {
      this.classifyTableDataMsgPage.pageIndex = val;
      this.fetchClassifyMaterialList();
    },
    handleSearchInputBlur() {
      // 通过 this.materialInput 调用接口请求查询数据
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  width: auto;
  height: 75%;
  padding: 5px;
  margin-top: 10px;
  display: flex;
}
.left-table {
  width: 40%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
}
.right-table {
  width: 50%;
  height: 95%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 60px;
  overflow: hidden;
  overflow-y: scroll;
  .title {
    padding-left: 10px;
    border-left: 1px solid #ccc;
  }
}
.formItemName {
  margin-right: 10px;
}
.redStar::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
::v-deep .form-item-container {
  margin-bottom: 20px;
  .el-form-item__error {
    margin-left: 80px;
  }
}
// ::v-deep .materialTagTableContainer{
//   .current-row {
//     background-color: red !important;
//   }
// }
</style>
